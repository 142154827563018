import Articles from "./Articles";
import Filters from "./filters/Filters";
import SortDropdown from "./sort/SortDropdown";
import "./ArticlesPage.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import {
  fetchFilteredArticles,
  fetchFiltersList,
} from "../../../../store/articles/articles.slice";
import {
  Filter,
  FiltersObject,
} from "../../../../types/articlesPage/FiltersObject";
import { Data } from "../../../../types/articlesPage/FilteredArticlesDataType";
import { SortOrder } from "../../../../types/articlesPage/SortOrderEnum";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import FiltersMobile from "./filters/FiltersMobile";
import AppliedFilters from "./filters/AppliedFilters";
import { setSEO } from "../../../../utils/helpers/seo-function";
import { BASE_URL } from "../../../../config/constants";

const ArticlesPage = ({ cat1, cat2, cat3, menu }: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const dispatch: any = useDispatch();

  const { filtered_articles, filters_list } = useSelector(
    (store: any) => store.articles
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(12);
  const [filtersObject, setFiltersObject] = useState<FiltersObject>({
    article_filters: [],
  });

  const [minPrice, setMinPrice] = useState<number | null>(null);
  const [maxPrice, setMaxPrice] = useState<number | null>(null);
  const [order, setOrder] = useState<SortOrder | null>(null);

  const getTitle = () => {
    let title = t("proizvodi") || "Proizvodi";
    let items = [];
    if (menu.is_fetch) {
      if (cat1) {
        items = menu.article_category.filter((x: any) => x.slug === cat1);
        title = items[0] ? items[0].ac_name : "";
      }
      if (cat2) {
        items = items[0]
          ? items[0].subcategories.filter((y: any) => y.slug === cat2)
          : items;
        title = items[0] ? items[0].ac_name : "";
      }
      if (cat3) {
        items = items[0]
          ? items[0].subcategories.filter((z: any) => z.slug === cat3)
          : items;
        title = items[0] ? items[0].ac_name : "";
      }
    }
    return title;
  };

  const getCurrentCategorySlug = () => {
    if (cat3 !== undefined) {
      return cat3;
    } else if (cat2 !== undefined) {
      return cat2;
    } else if (cat1 !== undefined) {
      return cat1;
    } else {
      return null;
    }
  };

  const addOnLoadFilter = (newFilter: {
    filter_label: string;
    filter_values: string;
  }) => {
    setFiltersObject((prevFiltersObject) => {
      const existingIndex = prevFiltersObject.article_filters.findIndex(
        (filter) => filter.filter_label === newFilter.filter_label
      );

      if (existingIndex !== -1) {
        // Ako filter već postoji, dodajemo novu vrednost ako nije već prisutna
        const existingFilter = prevFiltersObject.article_filters[existingIndex];
        if (!existingFilter.filter_values.includes(newFilter.filter_values)) {
          const updatedFilter = {
            ...existingFilter,
            filter_values: [
              ...existingFilter.filter_values,
              newFilter.filter_values,
            ],
          };
          const updatedFilters = prevFiltersObject.article_filters.map(
            (filter, index) =>
              index === existingIndex ? updatedFilter : filter
          );
          return { ...prevFiltersObject, article_filters: updatedFilters };
        }
        return prevFiltersObject;
      } else {
        // Ako filter ne postoji, kreiramo novi
        const newArticleFilter = {
          filter_label: newFilter.filter_label,
          filter_values: [newFilter.filter_values],
        };
        return {
          ...prevFiltersObject,
          article_filters: [
            ...prevFiltersObject.article_filters,
            newArticleFilter,
          ],
        };
      }
    });
  };
  const addFilter = (attributeName: string, filter_values: string[]) => {
    const newFilter: Filter = {
      filter_label: attributeName,
      filter_values,
    };

    let updatedFilters;
    if (filter_values.length === 0) {
      // Ukloni filter ako nema vrednosti
      updatedFilters = filtersObject.article_filters.filter(
        (filter) => filter.filter_label !== attributeName
      );
    } else {
      // Ažuriraj ili dodaj filter
      const existingFilter = filtersObject.article_filters.find(
        (filter) => filter.filter_label === newFilter.filter_label
      );
      updatedFilters = existingFilter
        ? filtersObject.article_filters.map((filter) =>
            filter.filter_label === newFilter.filter_label
              ? { ...filter, ...newFilter }
              : filter
          )
        : [...filtersObject.article_filters, newFilter];
    }
    setFiltersObject({ article_filters: updatedFilters });
    updateURL(updatedFilters, order, currentPage, pageSize, minPrice, maxPrice);
  };
  // Function to get JSON string
  const getJsonString = (filtersObject: FiltersObject): string => {
    return JSON.stringify(filtersObject);
  };

  const updateURL = (
    filters: Filter[],
    order: any,
    page: any,
    take: any,
    min: any,
    max: any
  ) => {
    const searchParams = new URLSearchParams();
    if (filters) {
      filters.forEach((filter) => {
        filter.filter_values.forEach((fv) => {
          searchParams.append(filter.filter_label, fv);
        });
      });
    }
    if (order) {
      searchParams.append("order", order);
    }
    if (page) {
      searchParams.append("page", page);
    }
    if (take) {
      searchParams.append("take", take);
    }
    if (min) {
      searchParams.append("min_price", min);
    }
    if (max) {
      searchParams.append("max_price", max);
    }
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const getFiltersFromURL = () => {
    const searchParams = new URLSearchParams(location.search);
    let urlParams: { filter_label: string; filter_values: string }[] = [];
    searchParams.forEach((value, key) => {
      if (key === "order") {
        setOrder(getSortOrder(value));
      } else if (key === "page") {
        setCurrentPage(parseInt(value));
      } else if (key === "take") {
        setPageSize(parseInt(value));
      } else if (key === "min_price") {
        setMinPrice(parseInt(value));
        urlParams.push({ filter_label: key, filter_values: value });
      } else if (key === "max_price") {
        setMaxPrice(parseInt(value));
        urlParams.push({ filter_label: key, filter_values: value });
      } else {
        urlParams.push({ filter_label: key, filter_values: value });
        addOnLoadFilter({ filter_label: key, filter_values: value });
      }
    });

    return urlParams;
  };

  function getSortOrder(value: string): SortOrder | null {
    switch (value) {
      case "name_asc":
        return SortOrder.NameAsc;
      case "name_desc":
        return SortOrder.NameDesc;
      case "price_asc":
        return SortOrder.PriceAsc;
      case "price_desc":
        return SortOrder.PriceDesc;
      case "rate_asc":
        return SortOrder.RateAsc;
      case "popular_asc":
        return SortOrder.PopularAsc;
      case "default":
        return null;
      default:
        return null;
    }
  }

  const getValidOrderValue = (value: string): "Valid" | "Invalid" => {
    if (Object.values(SortOrder).includes(value as SortOrder)) {
      return "Valid";
    } else {
      return "Invalid";
    }
  };

  const changeOrder = (option: any) => {
    setOrder(getSortOrder(option));
    updateURL(
      filtersObject.article_filters,
      getSortOrder(option),
      currentPage,
      pageSize,
      minPrice,
      maxPrice
    );
  };

  const changePageSize = (page: any, take: any) => {
    setCurrentPage(page);
    setPageSize(take);
    updateURL(
      filtersObject.article_filters,
      order,
      page,
      take,
      minPrice,
      maxPrice
    );
  };

  const changePrice = (value: any) => {
    setMinPrice(value[0]);
    setMaxPrice(value[1]);

    updateURL(
      filtersObject.article_filters,
      order,
      currentPage,
      pageSize,
      value[0],
      value[1]
    );
  };

  const extractPriceRange = (data: any) => {
    const priceFilter = data.find(
      (filter: any) => filter.filter_key === "Cijena"
    );
    if (!priceFilter) return {};

    const minPrice =
      priceFilter.filter_children.find(
        (child: any) => child.value_key === "min_price"
      )?.value_number || 0;
    const maxPrice =
      priceFilter.filter_children.find(
        (child: any) => child.value_key === "max_price"
      )?.value_number || 0;

    return { minPrice, maxPrice };
  };

  const prevDeps = useRef({ pathname: location.pathname });
  const isFirstChange = useRef(true);

  useEffect(() => {
    if (isFirstChange.current) {
      // Prva promena, samo ažuriraj flag
      isFirstChange.current = false;
    } else if (prevDeps.current.pathname !== location.pathname) {
      // Ako pathname nije jednak prethodnom, znači da se promenio
      // primenjeni filteri se resetuju
      setFiltersObject({
        article_filters: [],
      });
      setMinPrice(null);
      setMaxPrice(null);
    }

    // Ažuriraj prethodni pathname
    prevDeps.current = { pathname: location.pathname };
  }, [location.pathname]);

  useEffect(() => {
    if (cat3 !== undefined) {
      dispatch(
        fetchFiltersList({
          ac_slug: getCurrentCategorySlug(),
          url_filters: getFiltersFromURL(),
        })
      );
    }
  }, [dispatch, location, params]);

  useEffect(() => {
    let data: Data = {
      ac_slug: getCurrentCategorySlug(),
      page: currentPage,
      take: pageSize,
      // order: "name_asc", //sortiranje, nije obavezno, naknadno dodati
      // min_price: 0.0, //nije obavezno, naknadno dodati
      // max_price: 200.0, //nije obavezno, naknadno dodati
      // article_filters: getJsonString(filtersObject), //nije obavezno, naknadno dodati
    };
    if (order !== null && getValidOrderValue(order) === "Valid") {
      data.order = order;
    }
    if (minPrice !== null && minPrice >= 0.0) {
      data.min_price = minPrice;
    }
    if (maxPrice !== null && maxPrice >= 0.0) {
      data.max_price = maxPrice;
    }
    if (filtersObject.article_filters.length !== 0) {
      data.article_filters = getJsonString(filtersObject);
    }

    dispatch(fetchFilteredArticles(data));
  }, [
    dispatch,
    currentPage,
    order,
    minPrice,
    maxPrice,
    filtersObject,
    pageSize,
    location,
  ]);

  useEffect(() => {
    if (filtered_articles.is_fetch === true && filters_list.is_fetch === true) {
      const searchParams = new URLSearchParams(location.search);
      let keywords = [];
      if (order !== null && getValidOrderValue(order) === "Valid") {
        keywords.push("order: " + order);
      }
      if (minPrice !== null && minPrice >= 0.0) {
        keywords.push("min_price: " + minPrice);
      }
      if (maxPrice !== null && maxPrice >= 0.0) {
        keywords.push("max_price: " + maxPrice);
      }

      if (filtersObject.article_filters.length !== 0) {
        filtersObject.article_filters.forEach((f: any) => {
          keywords.push(f.filter_label + ": " + f.filter_values.join(", "));
        });
      }
      setSEO({
        title: getTitle(),
        description: getTitle(),
        keywords: keywords,
        url: BASE_URL + location.pathname + "?" + searchParams.toString(),
        image: "",
        type: "articles",
      });
    }
  }, [filtered_articles.is_fetch, filters_list.is_fetch, location]);

  return (
    <>
      <div className="Articles-Page-Line">
        <div className="Articles-Page-Subtitle">{getTitle()}</div>
      </div>
      <div className="Articles-Page">
        <div className="Articles-Page1">
          {filters_list.is_fetch && (
            <>
              <div className="Articles-Page1-Filter">
                <h2 className="Filter-Title">Filteri:</h2>
              </div>
              <Filters
                getCurrentCategorySlug={getCurrentCategorySlug}
                addFilter={addFilter}
                changePrice={changePrice}
                minPrice={minPrice}
                maxPrice={maxPrice}
                filterList={filters_list}
              />
            </>
          )}
        </div>
        <div className="Articles-Page2">
          <div className="Articles-Page-Row">
            <div className="Articles-Page-Col1">
              <FiltersMobile
                getCurrentCategorySlug={getCurrentCategorySlug}
                addFilter={addFilter}
                changePrice={changePrice}
                minPrice={minPrice}
                maxPrice={maxPrice}
                filterList={filters_list}
              />
            </div>
            <div className="Articles-Page-Col2">
              {filtered_articles.is_fetch && (
                <SortDropdown order={order} changeOrder={changeOrder} />
              )}
            </div>
          </div>
          <div className="Articles-Page-Row2">
            <AppliedFilters
              appliedFilters={filtersObject.article_filters}
              addFilter={addFilter}
              changePrice={changePrice}
              minPrice={minPrice}
              maxPrice={maxPrice}
              priceRange={extractPriceRange(filters_list.data)}
            />
            <Articles
              currentPage={currentPage}
              totalItems={filtered_articles.meta.item_count}
              pageSize={pageSize}
              changePageSize={changePageSize}
              filteredArticles={filtered_articles}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default ArticlesPage;
