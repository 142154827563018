import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const CleanURL = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const unwantedParams = [
      "utm_source",
      "utm_medium",
      "utm_campaign",
      "utm_term",
      "utm_content",
      "utm_id",
      "igshid", //instagram
      "fbclid", //facebook
      "twclid", //twitter
      "li_fat_id", //linkedin
      "waid", //whats app
      "gclid", //google ads 
      "ttclid", //tiktok ads
      "scid", //snapchat
      "ytclid", //youtube
      "pclid", //pinterest
      "rdclid", //redit
      "sp_cid", //spotify
      "msclkid", //microsoft ads 
      "zcid", //zoom 
    ]; // Lista neželjenih parametara
    const url = new URL(window.location.href);

    let paramsChanged = false;
    // Svi parametri koji pocinju sa utm_ da budu nepozeljni
    url.searchParams.forEach((param, key) => {
        if(key.startsWith("utm")){
            unwantedParams.push(key)
        }else if(key.startsWith("hsa")){
            unwantedParams.push(key)
        }else if(key.startsWith("_")){
            unwantedParams.push(key)
        }else if(key.startsWith("mc")){
            unwantedParams.push(key)
        }
    })
    // Proveri i ukloni neželjene parametre
    unwantedParams.forEach((param) => {
      if (url.searchParams.has(param)) {
        url.searchParams.delete(param);
        paramsChanged = true;
      }
    });



    // Ako su parametri uklonjeni, ažuriraj URL
    if (paramsChanged) {
      navigate(`${url.pathname}${url.search}`, { replace: true });
    }
  }, [location, navigate]);

  return null; // Ova komponenta samo čisti URL i ne prikazuje ništa
};

export default CleanURL;
