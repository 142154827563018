import "./FilterNav.scss";
import Filters from "./Filters";

const FilterNav = ({
  openBurger,
  closeBurger,
  getCurrentCategorySlug,
  addFilter,
  changePrice,
  minPrice,
  maxPrice,
  filterList,
}: any) => {

  return (
    <nav
      id="Filter-Nav"
      className="Filter-Nav"
      style={{ display: openBurger === true ? "inline" : "none" }}
    >
      {filterList.is_fetch && (
        <div className="Filter-Nav-Box">
          <div className="Filter-Nav-Box-Title">
            <h2 className="Filter-Title">Filteri:</h2>
          </div>
          <div className="Filter-Nav-Box-Items">
            <Filters
              getCurrentCategorySlug={getCurrentCategorySlug}
              addFilter={addFilter}
              changePrice={changePrice}
              minPrice={minPrice}
              maxPrice={maxPrice}
              filterList={filterList}
            />
          </div>
          {/* <div className="Filter-Nav-Box-Title">Primjeni</div> */}
        </div>
      )}
    </nav>
  );
};
export default FilterNav;
